module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"matomoUrl":"https://gatherforgainsgmailcom.matomo.cloud/","siteId":"1","enableLinkTracking":true,"debug":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Find Open Fitness Hangouts in Toronto | GatherForGains","short_name":"GatherForGains","start_url":"/","background_color":"#ffe491","theme_color":"#004ca3","icon":"src/components/img/icon-gfg.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1a62da95f9bf4b56b764c62dcdcf5e3d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-ME6F7FM9P5"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"exclude":["localhost","127.0.0.1"],"respectDNT":false,"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
